import React from 'react'


import { PortfolioWrapper, Column, Row, ProjectOverlay, ProjectImg, InnerContainer } from './portfolio-styles'

import { portfolio } from '../../utils/data'
import { getObjectArray, arrayChunk } from '../../utils/utility-methods'


const Portfolio = () => {
    const projects = getObjectArray(portfolio)
    const chunkedProjects = arrayChunk(projects, 3)
    return <PortfolioWrapper id="portfolio">
        {
            projects.length < 3 ?
                <Row>
                    <Column>{
                        projects.map((project) =>

                            <InnerContainer key={project.id}>
                                <ProjectImg id="image" src={project.img} />
                                <ProjectOverlay id="overlay">
                                    <div className="overlay-text"></div>
                                    <img src={project.img} />
                                    <div className="caption">
                                        <h3>{project.name}</h3>
                                        <ul>
                                            {
                                                project.technologies.map(tech =>
                                                    <li key={project.technologies.indexOf(tech)}>{tech} - </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </ProjectOverlay>
                            </InnerContainer>

                        )}</Column>
                </Row>
                :
                <Row>
                    <Column>
                        {
                            chunkedProjects[0].map(project =>
                                <a key={project.id} href={project.url} target='_blank'>
                                    <InnerContainer key={project.id}>
                                        <ProjectImg id="image" src={project.img} />
                                        <ProjectOverlay id="overlay">
                                            <div className="overlay-text"></div>
                                            <img src={project.img} />
                                            <div className="caption">
                                                <h3>{project.name}</h3>
                                                <ul>
                                                    {
                                                        project.technologies.map(tech =>
                                                            <li key={project.technologies.indexOf(tech)}>{tech} - </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </ProjectOverlay>
                                    </InnerContainer>
                                </a>
                            )
                        }
                    </Column>
                    <Column>
                        {
                            chunkedProjects[1].map(project =>
                                <a key={project.id} href={project.url} target='_blank'>
                                    <InnerContainer key={project.id}>
                                        <ProjectImg id="image" src={project.img} />
                                        <ProjectOverlay id="overlay">
                                            <div className="overlay-text"></div>
                                            <img src={project.img} />
                                            <div className="caption">
                                                <h3>{project.name}</h3>
                                                <ul>
                                                    {
                                                        project.technologies.map(tech =>
                                                            <li key={project.technologies.indexOf(tech)}>{tech} - </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </ProjectOverlay>
                                    </InnerContainer>
                                </a>
                            )
                        }
                    </Column>
                    <Column>
                        {

                            chunkedProjects[2].map(project =>
                                <a key={project.id} href={project.url} target='_blank'>
                                    <InnerContainer key={project.id}>
                                        <ProjectImg id="image" src={project.img} />
                                        <ProjectOverlay id="overlay">
                                            <div className="overlay-text"></div>
                                            <img src={project.img} />
                                            <div className="caption">
                                                <h3>{project.name}</h3>
                                                <ul>
                                                    {
                                                        project.technologies.map(tech =>
                                                            <li key={project.technologies.indexOf(tech)}>{tech} - </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </ProjectOverlay>
                                    </InnerContainer>
                                </a>
                            )
                        }
                    </Column>
                </Row>


        }

    </PortfolioWrapper>

}

export default Portfolio