import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    justify-content: center;
    @media(max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`

export const Blog = styled.div`
    display: flex;
    flex-flow: wrap;
    flex-direction: column;
    width: 30%;
    align-items: center;
    margin: 10px;
    /* flex-grow: 1; */

    @media(max-width: 800px) {
        max-width:60%;
    }

    a {
        text-decoration: none;
    }

    img {
        height: 400px;
        width:100%;
        margin-bottom: -22px;
    }

    #blog-desc {
        background-color: #343a40;
        height: 130px;
        color: white;
        padding: 10px;
    }
`