import React from 'react'

import { ServiceItem } from './services-styles'

const Service = (props) => {
    return (
        <ServiceItem>
            <img src={props.icon}/>
            <h3>{props.name}</h3>
            <p>{props.desc}</p>
            <ul>
                {
                    props.tech.length? props.tech.map(item => 
                        <li key={props.tech.indexOf(item)}>{item} - </li>
                    ): ''
                }
            </ul>
            
        </ServiceItem>
    )
}

export default Service