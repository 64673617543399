import React, { useState } from 'react'

import { sendMessage } from '../../utils/api'

import { Wrapper, Input, Form, TextArea, Submit } from './contact-me-styles'

const ContactMe = () => {

    const [messageDetails, setMessageDetails] = useState({ name: '', email: '', message: '', honeypot: '' })
    const [emailStatus, setEmailStatus] = useState(0)
    const [sendingStatus, setSendingStatus] = useState("")

    const handleMessage = async () => {
        try {
            if (!emailStatus || (messageDetails && messageDetails.honeypot)) {
                setEmailStatus(false)
                return
            }

            const fieldSet = !!(messageDetails.name && messageDetails.email && messageDetails.message)
            console.log(fieldSet)
            if (fieldSet) {
                const res = await sendMessage({ ...messageDetails })
                if (res.data.result === 'success') {
                    setSendingStatus("sent")
                    setMessageDetails({ name: '', email: '', message: '', honeypot: '' })
                } else {
                    setSendingStatus("Failed to send. Try again")
                }
            } else {
                setSendingStatus("Fields cannot be empty")
            }
        } catch (e) {
            console.log(e.message)
        }

    }

    const handleInput = (e) => {
        e.preventDefault();
        const field = e.target.name
        if (field === 'email') {
            const email = String(e.target.value).toLowerCase().trim()
            const validate = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            if (validate.test(email)) {
                setEmailStatus(true)
            } else {
                setEmailStatus(false)
            }
        }
        setMessageDetails({
            ...messageDetails,
            [field]: e.target.value
        })
    }

    return (
        <Wrapper id="contact-me">
            <Form>
                {
                    sendingStatus
                }
                <Input name="name" type="text" placeholder="Name" onChange={handleInput} value={messageDetails.name} />
                <Input name="email" onChange={handleInput} type="email" placeholder="Email" value={messageDetails.email} />
                <Input id="honeypot" type="text" name="honeypot" defaultValue={messageDetails.honeypot} />
                {
                    emailStatus === false ? 'Incorrect email' : ""
                }
                <TextArea name="message" onChange={handleInput} type="text" placeholder="Message" rows="4" value={messageDetails.message} />
                <Submit onClick={handleMessage}>Send Message</Submit>

            </Form>
        </Wrapper>
    )
}

export default ContactMe