import React from 'react'

import { Wrapper, Item } from './about-styles'

const About = () => {
    return (
        <Wrapper id="about-me">
            <Item>
                <img src='../../img/fearless.png' />
                <h2>Be Fearless</h2>
                <p>Fear stops us from realizing our full potential. Hence,
                    I always face challenges with courage and I believe nothing is impossible.
                    I am motivated by challenges.</p>
            </Item>
            <Item>
                <img src='../../img/water.png' />
                <h2>Be Water</h2>
                <p>I perfectly agree with Bruce Lee when he says "Be water". I am not rigid towards
                     change, I continuosly improve myself and adapt to changing market demands and new trends.</p>
            </Item>
            <Item>
                <img src='../../img/love.png' />
                <h2>Be Loving</h2>
                <p>I strive to create a positive impact where ever I find myself be it my professional teams, family, community etc. I work on creating stronger and happy relationships through kindness and love.</p>

            </Item>
        </Wrapper>
    )
}

export default About