import styled from "styled-components";

export const Salutation = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 3;

  h1 {
    font-size: 50px;
  }

  h1 span {
    position: relative;
  }

  h1 span:after {
    content: "";
    position: absolute;
    height: 16px;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: #8490ff;
  }

  p {
    font-size: 25px;
  }
`;

export const PicContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 3;
  position: relative;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none;

    .skill-icon {
      display: none;
    }
  }

  @media (min-width: 800px) and (max-width: 1000px) {
    justify-content: center;
  }
`;
export const Image = styled.img`
  width: 50%;
  transition: 1s;

  &:hover {
    cursor: pointer;
    width: 52%;
  }
`;

export const SkillOne = styled.img`
  position: absolute;
  left: ${(props) => props.position.left};
  top: ${(props) => props.position.top};
  right: ${(props) => props.position.right};
  bottom: ${(props) => props.position.bottom};
  width: 40px;
  z-index: -1;
  transition: all 1s;
`;

export const SkillTwo = styled.img`
  position: absolute;
  left: ${(props) => props.position.left};
  top: ${(props) => props.position.top};
  right: ${(props) => props.position.right};
  bottom: ${(props) => props.position.bottom};
  width: 40px;
  z-index: -1;
  transition: all 1s;

  ${Image}:hover & {
    left: -60px;
    top: 20px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background: #1f1f20;
  align-items: center;
  color: white;
  position: relative;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding-top: 100px; */
  }
`;
