import styled from 'styled-components'


export const PortfolioWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;

    h1 {
        font-size: 40px;
        margin-top: 40px;
    }
`

export const ProjectImg = styled.img`
    width: auto;
`

export const ProjectOverlay = styled.div`
    position: absolute;
    top:0;
    left:0;
    min-width: 100%;
    min-height: 100%;
    margin-bottom: 20px;
    vertical-align: middle;

    img {
        display: none;
    }

    .overlay-text {
        display: none;
    }

    .caption {
        display: none;
    }

    &:hover {
        cursor: pointer;
        
        img {
            display: block;
            width: 100%;
            filter: blur(4px) 
        }

        .overlay-text {
            position: absolute;
            display: block;
            background-color: #6c757d;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            z-index:2;
            opacity: 0.4;
            filter: alpha(opacity=40);
        }

        .caption {
            position: absolute;
            display: flex;
            flex-direction: column;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            color: red;
            z-index:3;
            color: white;
            align-items: center;
            justify-content: center;
            
            ul {
                margin: 0;
                padding-inline-start: 0;

                li{
                    display: inline;
                    margin: 0;
                }
                
            }
        }
        /* cursor: pointer;
        background-image: url(${props => props.img});
        background-size: cover;
        filter: blur(4px) */
    }
    
`

export const InnerContainer = styled.div`
    position: relative;
    margin-bottom:20px;
    z-index: 3;

    /* & ${ProjectOverlay}:hover + ${ProjectImg} {
        filter: blur(1px);
        -webkit-filter: blur(1px);
    } */
`

export const Column = styled.div`
    flex: 25%;
    max-width: 30%;
    padding: 0 10px;
    position: relative;

    img {
        vertical-align: middle;
        width: 100%;
    }

    @media (max-width: 800px) {
        flex: 50%;
        max-width: 50%;
    }

    @media (max-width: 600px) {
        flex: 100%;
        max-width: 100%;
    }

`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
`

