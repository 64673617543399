import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: #252527;
    color: white;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
`

export const Header = styled.div`
    display: flex;
`
export const Social = styled.div`
    display: flex;

    a {
        text-decoration: none;
        color: white;
        margin: 10px;
    }
`

export const Reserved = styled.div`
    color: #495057;
`
