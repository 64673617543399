import React from 'react'

import { blogs } from '../../utils/data'
import { getObjectArray, arrayChunk } from '../../utils/utility-methods'

import { Wrapper, Blog } from './articles-styles'

const Articles = () => {
    const blogArray = getObjectArray(blogs)
    return (
        <Wrapper id="articles">

            {
                blogArray.map(blog => {
                    return (
                        <Blog key={blog.id}>
                            <a href={blog.url} target='_blank'>
                                <img src={blog.img} />
                                <div id="blog-desc">
                                    <h3>{blog.title}</h3>
                                    <p>{blog.blurb}</p>
                                </div>

                            </a>
                        </Blog>
                    )
                })
            }


        </Wrapper>
    )
}

export default Articles