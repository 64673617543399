export const portfolio = {
  1: {
    id: 1,
    name: "Facebook interest finder",
    img: "../../img/getchCard.svg",
    type: "Personal",
    technologies: ["React", "Redux", "Saga", "Node Js", "Mongo db"],
    url: "https://getchapp.com/",
  },
  2: {
    id: 2,
    name: "Ecommerce Store",
    img: "../../img/proj1.png",
    type: "Personal",
    technologies: ["React", "Redux", "Saga", "Graph Ql", "PWA"],
    url: "https://ecom-react.herokuapp.com/",
  },

  3: {
    id: 3,
    name: "VextaTech - Landing Page",
    img: "../../img/projVexta.png",
    type: "VextaTech",
    technologies: ["HTML", "CSS", "jQuery", "Javascript"],
    url: "https://vextatech.com/",
  },
  4: {
    id: 4,
    name: "Kasa - Chat app",
    img: "../../img/kasa.png",
    type: "Personal",
    technologies: ["Node Js", "Socket io", "Handlebars"],
    url: "https://ben-chatapp.herokuapp.com/",
  },
  // 4: {
  //     id: 4,
  //     name: 'Currency Converter',
  //     img: '../../img/proj3.png',
  //     type: 'Personal',
  //     technologies: ['In progress'],
  //     url: '/'
  // },
  // 5: {
  //     id: 5,
  //     name: 'Restaurant',
  //     img: '../../img/proj4.png',
  //     type: 'Personal',
  //     technologies: ['React', 'Redux', 'Saga', 'Graph Ql', 'PWA'],
  //     url: 'https://ecom-react.herokuapp.com/'
  // },
  // 6: {
  //     id: 6,
  //     name: 'Ghana Stock Tracker',
  //     img: '../../img/proj5.png',
  //     type: 'Personal',
  //     technologies: ['React', 'Redux', 'Saga', 'Graph Ql', 'PWA'],
  //     url: 'https://ecom-react.herokuapp.com/'
  // },
  // 7: {
  //     id: 7,
  //     name: 'Emergency App',
  //     img: '../../img/proj6.png',
  //     type: 'Personal',
  //     technologies: ['React Native', 'Expo'],
  //     url: 'https://ecom-react.herokuapp.com/'
  // }
};

export const services = {
  front: {
    icon: "../../img/skill1.svg",
    name: "Front-end Development",
    description:
      "I love to code elegant interfaces to invoke amazing user experiences",
    technologies: [
      "Javascript",
      "React",
      "Saga",
      "Redux",
      "Apollo Graph QL",
      "Svelte Js",
      "Sapper",
      "Next Js",
      "Html 5",
      "Sass",
    ],
  },

  back: {
    icon: "../../img/skill2.svg",
    name: "Back-end Development",
    description:
      "I like working on applications to create a secure and fast backend",
    technologies: ["Node Js", "Mongo DB", "Firebase", "Graph QL"],
  },

  mobile: {
    icon: "../../img/skill3.svg",
    name: "Mobile Development",
    description:
      "I like creating mobile applications with Javascript technologies to easily scale across platforms",
    technologies: ["React native"],
  },
};

export const blogs = {
  blog1: {
    id: 1,
    img: "../../img/blog/blog1.png",
    url:
      "https://medium.com/@benkissi/using-react-context-to-manage-state-a043f30d4876",
    title: "Using React Context To Manage State",
    blurb:
      "The React.createContext() can take an optional default value, in this case world and it returns two components Provider and Consumer ",
  },
  blog2: {
    id: 2,
    img: "../../img/blog/blog2.png",
    url:
      "https://medium.com/@benkissi/how-to-create-a-not-found-page-in-react-95381566271d",
    title: "How to create a 404 not found page in react",
    blurb:
      "It is quite easy to handle 404 pages using react-router. First let us create a component for our 404 page.",
  },
  blog3: {
    id: 3,
    img: "../../img/blog/blog3.png",
    url:
      "https://medium.com/@benkissi/creating-multiple-layouts-in-react-react-router-v5-cebde25ff6e6",
    title: "Creating Multiple Layouts in React — React Router v5",
    blurb:
      "Working on various personal projects, I have come across several scenarios where I needed to create views/pages that had different layout structure.",
  },
};
