import axios from 'axios'

export const sendMessage = async ({ name, email, message }) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    let formData = new FormData();
    formData.append('name', name)
    formData.append('email', email)
    formData.append('message', message)

    const res = await axios.post('https://script.google.com/macros/s/AKfycbw7YuvM3Pn1Hn-KrJcFGLpDcH7GVOzxZVFtyds/exec', formData, {
        headers: headers
    })

    return res
}