import React from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom'

import Nav from './components/Nav/nav-component'
import Home from './pages/home/home-component'

import './App.scss';


function App() {
  return (
    <Router>
      <Nav/>
      <Route exact path='/' component={Home} />
    </Router>
  );
}

export default App;
