import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;

    @media(max-width: 800px) {
        flex-direction: column;
    }
`

export const Item = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
     p {
         line-height: 30px;
         text-align: center;
     }

    @media(max-width: 800px) {
        flex-direction: column;
    }
`