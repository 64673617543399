import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 100px;

    @media(max-width: 800px) {
        flex-direction: column;
    }
`

export const ServiceItem = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    align-items: center;
    

    p {
        text-align: center;
        line-height: 2;
    }

    li {
        display: inline;
        line-height: 2;
        align-self: center;
    }

    img {
        width: 70%;
    }

    @media(max-width: 800px) {
        margin-bottom: 50px;
        flex-direction: column;

        img {
            width: 40%;
        }
    }
`