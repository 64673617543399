import React from 'react'

import Service from './service-component'
import { Wrapper } from './services-styles'

import { getObjectArray } from '../../utils/utility-methods'
import { services } from '../../utils/data'

const Services = () => {
    const serviceArray = getObjectArray(services)
    return (
        <Wrapper id="services">
            {
                serviceArray.length ? serviceArray.map(item =>
                    <Service key={serviceArray.indexOf(item)} name={item.name} desc={item.description} tech={item.technologies} icon={item.icon} />
                ) : ''

            }
        </Wrapper>
    )
}

export default Services