import React from 'react'

import {getYear} from '../../utils/utility-methods'
import {Wrapper, Header, Social, Reserved} from './footer-styles'


const Footer = () => {
    return (
        <Wrapper>
            <Header>
                <h2>Ben Kissi</h2>
            </Header>
            <Social>
                <a href="https://www.linkedin.com/in/benkissi/" target="_blank">Linkedin</a>
                <a href="https://twitter.com/bfkissi" target="_blank">Twitter</a>
            </Social>
            <Reserved>
                © {getYear()} Ben Kissi, All Rights Reserved.
            </Reserved>
            
        </Wrapper>
    )
}

export default Footer