import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    justify-content: center;

    #honeypot {
        display: none;
    }
`

export const Input = styled.input`
    background: #252527 !important;
    border: 1px solid #4a4a4a;
    font-size: 12px;
    padding: 0 15px;
    border-radius: 0;
    min-width: 400px;
    min-height: 30px;
    font-size: 15px;
    margin: 5px;
    color: white;

    &:focus {
        outline-width: 0;
    }
`

export const Form = styled.form`
     display: flex;
     flex-direction: column;
     align-items: center;
`

export const TextArea = styled.textarea`
    background: #252527 !important;
    border: 1px solid #4a4a4a;
    font-size: 12px;
    padding: 0 15px;
    border-radius: 0;
    min-width: 400px;
    min-height: 30px;
    font-size: 15px;
    margin: 5px;
    color: white;

    &:focus {
        outline-width: 0;
    }
`

export const Submit = styled.h3`
    position: relative;
    z-index: 2;
    
    &:after {
        content: "";
        position: absolute;
        height: 10px;
        width: 100%;
        bottom: -3px;
        left: 0;
        z-index: -1;
        background: #8490ff;
        transition: all 1s;
    }

    &:hover {
        cursor: pointer;
        /* padding: 2px; */
        &:after {
            bottom: -3px;
            left: -43px;
            height: 30px;
            width: 200px;
        }
    }
`