import React from "react";

import * as Styles from "./hero-styles";

import "./hero-styles.scss";

const HeroSection = () => {
  return (
    <Styles.Wrapper id="home">
      <Styles.Salutation>
        <div>
          <span>Hi there</span>
          <h1>
            I am <span>Ben Kissi</span>
          </h1>
          <p>Fullstack Javascript Developer</p>
        </div>
      </Styles.Salutation>
      <Styles.PicContainer className="pic-container">
        <div id="skills-container">
          <img id="my-image" src="/img/pic.png" />

          <img className="skill-icon" id="skill1" src="/img/redux.png" />
          <img className="skill-icon" id="skill2" src="/img/css.png" />
          <img className="skill-icon" id="skill3" src="/img/graphql.png" />
          <img className="skill-icon" id="skill4" src="/img/html.png" />
          <img className="skill-icon" id="skill5" src="/img/react.png" />
          <img className="skill-icon" id="skill6" src="/img/node.png" />
        </div>
      </Styles.PicContainer>
    </Styles.Wrapper>
  );
};

export default HeroSection;
