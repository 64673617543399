import React from 'react'

import HeroSection from '../../components/hero-component/hero-component.jsx'
import Section from '../../components/section-component/section-component'
import About from '../../components/about-component/about-component'
import Portfolio from '../../components/portfolio-component/portfolio-component'
import Services from '../../components/services-component/services-component'
import Articles from '../../components/articles-component/articles-component'
import ContactMe from '../../components/contactme-component/contact-me-component'
import Footer from '../../components/footer-component/footer-component'

const Home = () => {
    return (
        <div>
            <HeroSection />
            <Section title="About me" subTitle='My guiding principles' fontColor='#fff' backgroundColor='#252527' setHeight='auto'>
                <About />
            </Section>
            <Section title="Portfolio" subTitle='All of my works here' fontColor='#fff' backgroundColor='#1f1f20' setHeight='auto'>
                <Portfolio />
            </Section>
            <Section title="What I Do" subTitle='My skills' fontColor='#fff' backgroundColor='#252527' setHeight='auto'>
                <Services />
            </Section>
            <Section title="Blogs" subTitle='What I write' fontColor='#fff' backgroundColor='#1f1f20' setHeight='auto'>
                <Articles />
            </Section>
            <Section title="Contact Me" fontColor='#fff' backgroundColor='#252527' setHeight='auto'>
                <ContactMe />
            </Section>
            <Footer />
        </div>
    )
}

export default Home